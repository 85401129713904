import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FAQPage = () => (
  <Layout>
    <SEO title="Faq" />
    <div className="faq">
        <h3>Have a question?</h3>
        <p>Send us an email support<span className="spamprotection" style={{ display: 'none'}}>CHARACTER SEQUENCE</span>@astrolab.app</p>
        {/* <form>
            <div class="fields">
                <div class="field half">
                    <input type="text" name="name" id="name" placeholder="Name" />
                </div>
                <div class="field half">
                    <input type="email" name="email" id="email" placeholder="Email" />
                </div>
                <div class="field">
                    <textarea name="message" id="message" placeholder="Message" rows="7"></textarea>
                </div>
            </div>
            <ul class="actions">
                <li><input type="submit" value="Send Message" class="button primary" /></li>
            </ul>
        </form> */}
    </div>
  </Layout>
)

export default FAQPage
